import * as React from "react"
import { graphql } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
import LayoutSectionIndex from "~templates/layout-section_index"
import DisplayGuidesInPrimary, { primary_orphans_to_cards } from "~components/guides_in_primary"
import NavProductData from "~data/nav_products.yml"
import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import { CommonHead } from "~components/Head/CommonHead"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as Includes from "~components/includes"
import Frontmatter from "~components/frontmatter"

// these came from the /products/index.md page
let frontmatter = {
  lang: "en",
  section: "products",
  section_safe: "products",
  home_url: "/",
  section_url: "/products/",
  crumb2override: "Products",
  fileslug: "",
  title: "Products | Fastly Documentation",
}

const EnProductsIndexPage = ({ data, location }) => {
  let PrimaryDatas = []

  NavProductData.forEach((element) => {
    const { crumb: pkey } = element

    let thisPrimary = data.primaries_p.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this products page
    p_frontmatter["section_url"] = "/products/"

    // let thisPrimaryGuides = data.guides_in_primarys_p.group.find( ggip => ggip.fieldValue === pkey)

    let myOrphans = data.primary_orphans_p.group.find((g) => g.fieldValue === pkey)

    PrimaryDatas.push({
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      pbody: thisPrimary.body,
      sec_cards: [],
      primary_orphans: primary_orphans_to_cards(p_frontmatter, myOrphans),
    })
  })

  let mdx_components = {Frontmatter, ...Includes}

  let title_content = {
    title: "Product Documentation",
    desc: (
      <>
        These articles describe the features, functionality, and performance of Fastly's products and services and their applicable SLAs. These articles also contain information about Fastly’s use of third-party technology or distributed software in our products and any certificates and compliance programs applicable to our products.<br />
        <br />
        Some products may be included as part of packaged offerings that allow you access to multiple delivery, security, or serverless compute services bundled together. Packaged offerings provide turnkey solutions with predictable pricing so you can fully support and protect your web applications and APIs. Packaged offering contents and entitlements are detailed on the <a href="https://www.fastly.com/package-entitlements/">pricing page</a>.
      </>
    ),
    icon: "platform",
  }

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutSectionIndex location={location} title_content={title_content}>
          {PrimaryDatas.map((datum) => {
            const { pfrontmatter, pbody, sec_cards, primary_orphans } = datum
            return (
              <DisplayGuidesInPrimary
                frontmatter={pfrontmatter}
                body={<MDXProvider components={mdx_components}>
                        <MDXRenderer>{pbody}</MDXRenderer>
                      </MDXProvider>}
                sec_cards={sec_cards}
                primary_orphans={primary_orphans}
              />
            )
          })}
        </LayoutSectionIndex>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default EnProductsIndexPage

export const query = graphql`
  query {
    guides_in_primarys_p: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/_products/" }
        frontmatter: { unlisted: { ne: true }, orphan: { ne: true } }
      }
        sort: { fields: [frontmatter___order, slug], order: [ASC, ASC] }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
          }
        }
      }
    }
    primaries_p: allMdx(filter: { fileAbsolutePath: { regex: "/_products_primary/" } }) {
      nodes {
        fileAbsolutePath
        body
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    primary_orphans_p: allMdx(
      filter: {
        frontmatter: {
          # orphan: {eq: true}
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_products//" }
      }
      sort: { fields: [frontmatter___order, slug], order: [ASC, ASC] }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          excerpt
          frontmatter {
            primary_nav
            # secondary_nav
            header
            # unlisted
            nav_title
            # orphan
          }
        }
      }
    }
  }
`

export const Head = (props) => {
  return <>
    <title>{frontmatter.title}</title>
    <CommonHead />
    <SeoHead frontmatter={frontmatter} {...props} />
    <FavHead />
  </>
}
